import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Container, Grid, Paper, TextField, Typography, CardMedia } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
//import OpenAI from "openai";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";
import LoadingButton from '@mui/lab/LoadingButton';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const AiImage = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [ loadingAPI, setLoadingAPI ] = useState(false);
    const [ loadingImageAPI, setLoadingImageAPI ] = useState(false);
    const [ loadingSaveImage, setLoadingSaveImage ] = useState(false);
    const [ newMessage, setNewMessage ] = useState("");
    const [ aiReply, setAiReply ] = useState("");
    const [ aiImageURL, setAiImageURL ] = useState("");
    
    //console.log('authUser ', authUser);
    
    const handleApi = (method) => {
        const aiApi = httpsCallable(functionsInstance, 'fireactjsSaas-aiApi');
        setAiReply("");
        setAiImageURL("");
        switch (method) {
            case "chat" :
                setLoadingAPI(true);
                aiApi({method: method, message: newMessage}).then((res) => {
                    setLoadingAPI(false);
                    setNewMessage("");
                    setAiReply(res.data.received.choices[0].message.content);
                    //console.log('message ', res.data.received.choices[0].message.content);
                    //console.log('res ', res);
                }).catch(error => {
                    //setError(error.message);
                    //setProcessing(false);
                    console.log('error ', error);
                });
                return;
                
            case "image":
                setLoadingImageAPI(true);
                aiApi({method: method, message: newMessage}).then((res) => {
                    setLoadingImageAPI(false);
                    setNewMessage("");
                    setAiImageURL(res.data.received.data[0].url);
                    //console.log('image URL ', res.data.received.data[0].url);
                    //console.log('res ', res);
                }).catch(error => {
                    //setError(error.message);
                    //setProcessing(false);
                    console.log('error ', error);
                });
                return;
                
            case "saveImage" :
                setLoadingSaveImage(true);
                aiApi({method: method, imgURL: aiImageURL}).then((res) => {
                    setLoadingSaveImage(false);
                    //console.log('res ', res);
                }).catch(error => {
                    //setError(error.message);
                    //setProcessing(false);
                    console.log('error ', error);
                });
                return;
        }
    }

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        /*
        async function fetchAiData() {
            try {
                const openai = new OpenAI({ apiKey: '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', dangerouslyAllowBrowser: true });
                const completion = await openai.chat.completions.create({
                    messages: [{ role: "system", content: "which country is the biggest in the world?" }],
                    model: "gpt-3.5-turbo",
                  });

                  console.log("openai ", completion.choices[0]);
            } catch (e) {
                console.log("openai error: ", e);
            }
        };
        fetchAiData();
         */

        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                  <SetPageTitle title="OpenTech+" />
                  <Paper>
                     <Box sx={{ flexGrow: 1, p: 0, width: '100%', minHeight: '100vh'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={12}>
                             <PageSection>
                                 <TextField value={newMessage} sx={{verticalAlign: 'bottom', width:'100%'}} id="new-message" label="New Topic" variant="standard" onChange={(e) => {setNewMessage(e.target.value)}} />
                                 <Box sx={{paddingTop: 1}}>
                                     <LoadingButton variant="outlined" startIcon={<TextsmsOutlinedIcon />} onClick={() => {
                                         handleApi("chat");
                                     }} loading={loadingAPI} loadingPosition="start" sx={{marginRight: 1}}>
                                       Ask
                                     </LoadingButton>
                                     <LoadingButton variant="outlined" startIcon={<PhotoSizeSelectActualOutlinedIcon />} onClick={() => {
                                         handleApi("image");
                                     }} loading={loadingImageAPI} loadingPosition="start">
                                       Image
                                     </LoadingButton>
                             </Box>
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={12}>
                             <PageSection>
                                {aiReply !== "" && (
                                 <Typography variant="body1" align="left" gutterBottom>
                                    {aiReply}
                                 </Typography>
                                )}
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={12}>
                             <PageSection>
                                 {(aiImageURL !== "") && (<>
                                  <img
                                    src={`${aiImageURL}`}
                                    style={{
                                     maxHeight: "100%",
                                     maxWidth: "100%",
                                    }}
                                  />
                                  {(authUser.user !== null) && (
                                  <Box sx={{paddingTop: 1}}>
                                      <LoadingButton variant="outlined" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {
                                          handleApi("saveImage");
                                      }} loading={loadingSaveImage} loadingPosition="start">
                                        Save
                                      </LoadingButton>
                                  </Box>
                                  )}
                                 </>)}
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={8}>
                             <PageSection>
                     
                             </PageSection>
                           </Grid>
                         </Grid>
                    </Box>
                    <Footer />
                  </Paper>
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default AiImage;

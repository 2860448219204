import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";

export default function Footer() {
    return (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="subtitle1">
              {'Copyright © '}{`${new Date().getFullYear()} OpenTech+. All rights reserved. | Music | Gallery | Privacy`}
            </Typography>
          </Grid>
        </Grid>
    )
}

import { Alert, Box, Button, Container, Grid, Paper, TextField, Typography, CardMedia } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, FireactContext, SetPageTitle } from "../../.";
import { deleteUser, getAuth } from "firebase/auth";
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import {PageSection} from "../../../../template/TemplateElements";
import Footer from "../../../../template/Footer";
import LoadingButton from '@mui/lab/LoadingButton';
import WebhookIcon from '@mui/icons-material/Webhook';
import { httpsCallable } from 'firebase/functions';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

export const UserWallet = ({loader}) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [ newMessage, setNewMessage ] = useState("");
    const [ web3Message, setWeb3Message ] = useState("");
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    
    const [ loadingAPI, setLoadingAPI ] = useState(false);
    const [ loadingTokenMetadata, setLoadingTokenMetadata ] = useState(false);
    const [ tokenMetadata, setTokenMetadata] = useState(null);
    
    const title = "Wallet";
    const navigate = useNavigate();

    const auth = getAuth();

    const { config } = useContext(FireactContext);
    const pathnames = config.pathnames;
    
    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        
        const defaultPermissions = [];
        const adminPermissions = [];
        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }
        
        // make sure Firestore Database rules give access to this collection/doc
        const contractMessageDocRef = doc(firestoreInstance, "web3", "contractMessage");
        
        onSnapshot(contractMessageDocRef,
          (doc) => {
            const data = doc.data();
            setWeb3Message(data.message);
            //console.log("Current data: ", doc.data());
            setLoaded(true);
          },
          (error) => {
            setLoaded(true);
            setError(error.message);
          });
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const handleApi = (method) => {
        //const web3Api = httpsCallable(functionsInstance, 'fireactjsSaas-mintbaseApi');
        //web3Api({method:"greet"}).then((res) => {
        const web3Api = httpsCallable(functionsInstance, 'fireactjsSaas-nearApi');
        
        switch (method) {
            case "updateMessage" :
                setLoadingAPI(true);
                web3Api({method: method, message: newMessage}).then((res) => {
                    setLoadingAPI(false);
                    setNewMessage("");
                    console.log('res ', res);
                }).catch(error => {
                    //setError(error.message);
                    //setProcessing(false);
                    console.log('error ', error);
                });
                return;
                
            case "getOtpMetadata":
                setLoadingTokenMetadata(true);
                web3Api({method: method}).then((res) => {
                    setLoadingTokenMetadata(false);
                    setTokenMetadata(res.data.received);
                    console.log('res ', res.data.received);
                }).catch(error => {
                    console.log('error ', error);
                });
                return;
        }
    }
    
    return (
    <>
        {loaded?(
         <Container maxWidth={false} disableGutters>
             <SetPageTitle title="Wallet" />
              <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
              <Grid container spacing={0}>
                    <Grid xs={12} md={12}>
                      <PageSection>
                        {web3Message}
                      </PageSection>
                    </Grid>
                    <Grid xs={12} md={12}>
                       <Grid container spacing={0}>
                           <Grid xs={12} md={4}>
                               <PageSection>
                                 <TextField value={newMessage} sx={{verticalAlign: 'bottom', paddingRight: 1}} id="new-message" label="New Message" variant="standard" onChange={(e) => {setNewMessage(e.target.value)}} />
                                 <LoadingButton variant="outlined" startIcon={<WebhookIcon />} onClick={() => {
                                     handleApi("updateMessage");
                                 }} loading={loadingAPI} loadingPosition="start">
                                   Send
                                 </LoadingButton>
                               </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                               <PageSection>
                                 <LoadingButton variant="outlined" startIcon={<MonetizationOnOutlinedIcon />} onClick={() => {
                                     handleApi("getOtpMetadata");
                                 }} loading={loadingTokenMetadata} loadingPosition="start">
                                   OTP Token
                                 </LoadingButton>
                                 {tokenMetadata && (
                                                    <Box sx={{p: 1}}>
                                        <CardMedia sx={{ p: 0, width: 40, display: 'inline-block', verticalAlign: 'middle', borderRadius: '50%'}}  component='img' src={tokenMetadata.icon} /> {tokenMetadata.name}
                                    </Box>
                                 )}
                               </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection>
                                 
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection>
                              
                             </PageSection>
                           </Grid>
                       </Grid>
                    </Grid>
                  </Grid>
             </Box>
             <Footer />
         </Container>
        ):(
            <>{loader}</>
        )}
    </>
    )
}


import * as React from 'react';
import { useContext } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Outlet, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import InputBase from '@mui/material/InputBase';
//import VimeoPlayer from "./VimeoPlayer";
import { getAuth, signOut } from "firebase/auth";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import OpenTechPlustPlayer from "./OpenTechPlustPlayer";
import NestedList from "./NestedList";
import SubMenu from "./SubMenu";
import { AuthContext, FireactContext } from '../lib/core';
import ImageIcon from '@mui/icons-material/Image';
import SubjectIcon from '@mui/icons-material/Subject';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

//https://mui.com/material-ui/react-app-bar/

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const client = {};

function OpenTechPlusTemplate(props) {
  const { authUser } = useContext(AuthContext);
  //console.log("template props ", props);
  //console.log("index ", props.extensionMenu.indexOf("groupAdmin"))
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {config} = useContext(FireactContext);
  const signInPath = config.pathnames.SignIn;
  const pathnames = config.pathnames;
  const profileUrl = pathnames.UserProfile;
  const walletUrl = pathnames.UserWallet;
  const subscriptionUrl = pathnames.ListSubscriptions;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    
  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {walletUrl && [
          <MenuItem onClick={
              () => {
                  handleMenuClose();
                  navigate(walletUrl);
              }}>
               <IconButton
                 size="small"
                 aria-label="wallete of current user"
                 aria-controls="primary-search-account-menu"
                 aria-haspopup="true"
                 color="inherit"
               >
                 <AccountBalanceWalletOutlinedIcon />
               </IconButton>
               Wallet
           </MenuItem>
        ]}
        {profileUrl && [
        <MenuItem onClick={
            () => {
                handleMenuClose();
                navigate(profileUrl);
            }}>
             <IconButton
               size="small"
               aria-label="account of current user"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
             >
               <AccountBoxIcon />
             </IconButton>
             Profile
         </MenuItem>
        ]}
        {authUser.user && (<MenuItem onClick={(e)=>{
          e.preventDefault();
          handleMenuClose();
          const auth = getAuth();
          signOut(auth).then(() => {
              document.location.href = "/";
          });
        }}>
           <IconButton
             size="small"
             aria-label="account of current user"
             aria-controls="primary-search-account-menu"
             aria-haspopup="true"
             color="inherit"
           >
             <ExitToAppIcon />
           </IconButton>
          Logout
        </MenuItem>)}
      </Menu>
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <NavLink to="/" style={{color: 'inherit', textDecoration:'none'}} key="home">
          <Typography variant="h6" sx={{ my: 2 }}>
            OpenTech+
          </Typography>
      </NavLink>
      <Divider />
      <List>
          {subscriptionUrl && [
            <NavLink to={subscriptionUrl} style={{textDecoration:'none'}} key="subscription">
                <ListItemButton>
                    <ListItemIcon><SubscriptionsIcon /></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Groups</Typography>} />
                </ListItemButton>
            </NavLink>
          ]}
         <NavLink to="/media" style={{textDecoration:'none'}} key="media">
           <ListItem key="media" disablePadding>
             <ListItemButton>
               <ListItemIcon><ImageIcon /></ListItemIcon>
               <ListItemText primary={<Typography color="textPrimary">Media</Typography>} />
             </ListItemButton>
           </ListItem>
         </NavLink>
         <NavLink to="/note" style={{textDecoration:'none'}} key="note">
             <ListItem key="note" disablePadding>
               <ListItemButton>
                 <ListItemIcon><SubjectIcon /></ListItemIcon>
                 <ListItemText primary={<Typography color="textPrimary">Notes</Typography>} />
               </ListItemButton>
             </ListItem>
         </NavLink>
         <NavLink to="/ai" style={{textDecoration:'none'}} key="ai">
             <ListItem key="ai" disablePadding>
               <ListItemButton>
                 <ListItemIcon><AutoStoriesIcon /></ListItemIcon>
                 <ListItemText primary={<Typography color="textPrimary">AI</Typography>} />
               </ListItemButton>
             </ListItem>
         </NavLink>
         <NavLink to="/stats" style={{textDecoration:'none'}} key="stats">
             <ListItem key="stats" disablePadding>
               <ListItemButton>
                 <ListItemIcon><BarChartIcon /></ListItemIcon>
                 <ListItemText primary={<Typography color="textPrimary">Stats</Typography>} />
               </ListItemButton>
             </ListItem>
         </NavLink>
      </List>
      <NestedList extensionMenu={props.extensionMenu} />
      <Divider key="nested-list"/>
      {!authUser.user && [
         <NavLink to={signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash} style={{textDecoration:'none'}} key="signin">
           <ListItemButton>
               <ListItemIcon><LoginIcon /></ListItemIcon>
               <ListItemText primary={<Typography color="textPrimary">Sign In</Typography>} />
           </ListItemButton>
       </NavLink>
      ]}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
         
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
       <Menu
         anchorEl={mobileMoreAnchorEl}
         anchorOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         id={mobileMenuId}
         keepMounted
         transformOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         open={isMobileMenuOpen}
         onClose={handleMobileMenuClose}
       >
         <MenuItem>
           <IconButton size="large" aria-label="show 4 new mails" color="inherit">
             <Badge badgeContent={4} color="error">
               <MailIcon />
             </Badge>
           </IconButton>
           Messages
         </MenuItem>
         <MenuItem>
           <IconButton
             size="large"
             aria-label="show 18 new notifications"
             color="inherit"
           >
             <Badge badgeContent={18} color="error">
               <NotificationsIcon />
             </Badge>
           </IconButton>
           Notifications
         </MenuItem>
         {walletUrl && [
         <MenuItem onClick={
           () => {
               handleMenuClose();
               navigate(walletUrl);
           }}>
             <IconButton
               size="large"
               aria-label="wallet of current user"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
             >
               <AccountBalanceWalletOutlinedIcon />
             </IconButton>
             Wallet
          </MenuItem>
         ]}
         {profileUrl && [
         <MenuItem onClick={
            () => {
                handleMenuClose();
                navigate(profileUrl);
            }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountBoxIcon />
              </IconButton>
              Profile
          </MenuItem>
         ]}
         {authUser.user && (
         <MenuItem onClick={(e)=>{
              e.preventDefault();
              handleMenuClose();
              const auth = getAuth();
              signOut(auth).then(() => {
                  document.location.href = "/";
              });
         }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <ExitToAppIcon />
              </IconButton>
              Logout
        </MenuItem>)}
       </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <NavLink to="/" style={{color: '#fff', textDecoration:'none'}} key="home">
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 0.1, display: { xs: 'none', md: 'block' } }}
              >
                OpenTech+
              </Typography>
          </NavLink>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {subscriptionUrl && [
                <NavLink to={subscriptionUrl} style={{textDecoration:'none'}} key="subscription">
                  <Button key="profile" sx={{ color: '#fff', textTransform: 'none' }}>
                    Groups
                  </Button>
                </NavLink>
            ]}
            <NavLink to="/media" style={{textDecoration:'none'}} key="media">
                <Button key="media" sx={{ color: '#fff', textTransform: 'none' }}>
                  Media
                </Button>
            </NavLink>
            <NavLink to="/note" style={{textDecoration:'none'}} key="note">
              <Button key="note" sx={{ color: '#fff', textTransform: 'none' }}>
                Notes
              </Button>
            </NavLink>
            <NavLink to="/ai" style={{textDecoration:'none'}} key="ai">
                <Button key="ai" sx={{ color: '#fff', textTransform: 'none' }}>
                  AI
                </Button>
            </NavLink>
            <NavLink to="/stats" style={{textDecoration:'none'}} key="stats">
                <Button key="stats" sx={{ color: '#fff', textTransform: 'none' }}>
                  Stats
                </Button>
            </NavLink>
            <SubMenu extensionMenu={props.extensionMenu} />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          {!authUser.user && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button aria-label="sign in" color="inherit" sx={{textTransform: 'none'}} onClick={(e)=>{
                    e.preventDefault();
                    handleMenuClose();
                    document.location.href = signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash
                   }}>
                   <LoginIcon sx={{marginRight: "5px"}}/> Sign In
                </Button>
              </Box>
          )}
          {authUser.user && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
          )}
          {authUser.user && (
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
          )}
        </Toolbar>
      </AppBar>
      {authUser.user && renderMobileMenu}
      {renderMenu}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
          <Box component="main" sx={{
              flexGrow: 1,
              backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              height: '100vh',
              overflow: 'auto'
          }}>
              <DrawerHeader />
          {/*<VimeoPlayer embedId="16180248" />*/}
          {/*<div style={{position: 'relative'}}>
                  <Box mt={5} ml={3} mr={3} mb={3}>
                    <Outlet />
                  </Box>
            </div>*/}
              <Outlet />
              <OpenTechPlustPlayer client={client}/>
          </Box>
    </Box>
  );
}

OpenTechPlusTemplate.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default OpenTechPlusTemplate;

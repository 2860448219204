import * as React from 'react';
import Fab from '@mui/material/Fab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const playlist = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/opentechplus-3f7ac.appspot.com/o/music%2Fsunshine-whistle-175139.mp3?alt=media&token=d48333a6-81e3-496f-bbca-71c7d7256edc' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/opentechplus-3f7ac.appspot.com/o/music%2Fjoyful-jingle-173919.mp3?alt=media&token=9fa2a938-aa0f-44e8-9c5e-8378e725af23' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/opentechplus-3f7ac.appspot.com/o/music%2Fwhistle-vibes-172471.mp3?alt=media&token=be21e366-d670-49d5-9647-a8db13015d43' },
]

export default function OpenTechPlustPlayer({ client }) {
  const [open, setOpen] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    
  const [currentTrack, setTrackIndex] = React.useState(0)
  const handleClickNext = () => {
          //console.log('click next')
            setTrackIndex((currentTrack) =>
                currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
            );
  };
    
    const handleClickPre = () => {
              setTrackIndex((currentTrack) =>
                  currentTrack < playlist.length ? currentTrack - 1 : 0
              );
    };
      
  const handleEnd = () => {
        //console.log('end')
        setTrackIndex((currentTrack) =>
                currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
            );
        if (currentTrack == playlist.length - 1) setPlaying(false);
  }

  return (
    <div>
      <Fab color="primary" aria-label="Sensors" style={{position: 'fixed', bottom: 80, right: 20}} onClick={handleClickOpen}>
          {playing?(
             <PauseCircleFilledIcon />
          ):(
             <PlayCircleIcon/>
          )}
      </Fab>
      <Dialog
          fullWidth
          maxWidth="md"
          sx={{
          "& .MuiDialog-paper": {
              margin: 0,
              width: "100%",
              'border-radius': 0,
              position: "absolute",
              bottom:0,
              height: "60vh"
          }
          }}
          BackdropProps={{
            invisible: true
          }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Play with others"}
            <ExpandMoreOutlinedIcon onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 18,
              }}
            />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <AudioPlayer
            style = {{padding: 0, backgroundColor: "transparent", boxShadow: "none"}}
            autoPlay
            /*customProgressBarSection={[]}
            customAdditionalControls={[]}
            customVolumeControls={[]}
            showJumpControls={false}
            src="https://firebasestorage.googleapis.com/v0/b/opentechplus-3f7ac.appspot.com/o/music%2Fsunshine-whistle-175139.mp3?alt=media&token=d48333a6-81e3-496f-bbca-71c7d7256edc"*/
            onPlaying = {e => setPlaying(true)}
            onPause = {e => setPlaying(false)}
            src={playlist[currentTrack].src}
            showSkipControls
            onClickNext={handleClickNext}
          onClickPrevious={handleClickPre}
            onEnded={handleEnd}
          />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

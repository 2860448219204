import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import React, { useContext, useEffect, useState } from "react";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { getStorage, ref } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, Button } from "@mui/material";
import { v4 as uuid, v5 as uuidv5 } from 'uuid';

export default function ImagesDropzone({ setImageList }) {
   const [imagesRef, setImagesRef] = useState(null);
   const { authInstance, firestoreInstance, functionsInstance, firebaseApp } = useContext(AuthContext);
   const { config } = useContext(FireactContext);
   useEffect(() => {
        //console.log("firestoreInstance ", firestoreInstance);
        //console.log("firebaseApp ", firebaseApp);
        const storage = getStorage();
        // Create a child reference
        const imagesRef = ref(storage, 'images');
       // imagesRef now points to 'images'
        setImagesRef(imagesRef);
        //console.log("imagesRef ", imagesRef);
        
   },[authInstance, config.saas.permissions, firestoreInstance]);
    
   const onDrop = (acceptedFiles) => {
      //console.log("imagesRef ", imagesRef);
      if (acceptedFiles.length > 0) {
         const newImages = Array.from(acceptedFiles).map((file) => {
            const uniqueFilename = uuid();
            return {
               file: file,
               fileName: uniqueFilename,
               status: "CREATED",
               storageRef: ref(imagesRef, uniqueFilename),
               downloadURL: "",
               description: "",
            };
         });

         setImageList((prevState) => [...prevState, ...newImages]);
      }
   };

   const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      accept: {
         'image/*': ['.jpeg', '.png', '.jpg', '.gif']
      },
      noClick: true,
      noKeyboard: true,
   });

   return (
      <div {...getRootProps()}>
         <input {...getInputProps()} />
         <Grid container direction="column" spacing={2}>
            <Grid
               item
               container
               direction="column"
               alignItems="center"
               spacing={1}
            >
               <Grid item>
                  <Typography align="center">
                     {isDragActive
                        ? "Drop Images here ..."
                        : "Drag 'n' drop Images here, or:"}
                  </Typography>
               </Grid>
               <Grid item>
                  <Button onClick={open} variant="contained" color="primary">
                     Select Images...
                  </Button>
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
}

import * as React from 'react';
import { useContext } from "react";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { FireactContext } from '../lib/core';
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import WebIcon from '@mui/icons-material/Web';
import PaymentsIcon from '@mui/icons-material/Payments';
import Divider from '@mui/material/Divider';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

export default function NestedList(props) {
  const [openManageGroup, setOpenManageGroup] = React.useState(true);
    
  const { subscriptionId } = useParams();
  const {config} = useContext(FireactContext);
  const pathnames = config.pathnames;
  const groupHomeUrl = pathnames.Subscription.replace(":subscriptionId", subscriptionId);
  const settingsUrl = pathnames.Settings.replace(":subscriptionId", subscriptionId);
  const listUsersUrl = pathnames.ListUsers.replace(":subscriptionId", subscriptionId);
  const postUrl = pathnames.Post.replace(":subscriptionId", subscriptionId);
  const changePlanUrl = pathnames.ChangePlan.replace(":subscriptionId", subscriptionId);
  const listInvoicesUrl = pathnames.ListInvoices.replace(":subscriptionId", subscriptionId);

  const handleClick = () => {
    setOpenManageGroup(!openManageGroup);
  };

  return (
    <>
    {subscriptionId && (<>
      <Divider key="nested-list-group"/>
       <List
         sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
         component="nav"
         aria-labelledby="nested-list-subheader"
         subheader={
           <ListSubheader component="div" id="nested-list-subheader">
            Group
           </ListSubheader>
         }
       >
          {groupHomeUrl && [
              <NavLink to={groupHomeUrl} style={{textDecoration:'none'}} key="group-home">
                  <ListItemButton>
                      <ListItemIcon><GridViewOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Dashboard</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {((props.extensionMenu.indexOf("groupAdmin") > -1) || (props.extensionMenu.indexOf("groupAccess") > -1)) && (<>
            {postUrl && [
              <NavLink to={postUrl} style={{textDecoration:'none'}} key="post">
                  <ListItemButton>
                      <ListItemIcon><AutoStoriesOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Posts</Typography>} />
                  </ListItemButton>
              </NavLink>
            ]}
          </>)}
          {(props.extensionMenu.indexOf("groupAdmin") > -1) && (<>
            <ListItemButton onClick={(e)=>{
                e.stopPropagation();
                handleClick();
              }}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Manage" />
              {openManageGroup ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openManageGroup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {listUsersUrl && [
                  <NavLink to={listUsersUrl} style={{textDecoration:'none'}} key="listusers">
                   <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon><GroupIcon /></ListItemIcon>
                          <ListItemText primary={<Typography color="textPrimary">Users</Typography>} />
                   </ListItemButton>
                  </NavLink>
                ]}
                {changePlanUrl && [
                     <NavLink to={changePlanUrl} style={{textDecoration:'none'}} key="settings">
                      <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon><WebIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="textPrimary">Change Plan</Typography>} />
                        </ListItemButton>
                     </NavLink>
                ]}
                {listInvoicesUrl && [
                   <NavLink to={listInvoicesUrl} style={{textDecoration:'none'}} key="billing">
                      <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon><PaymentsIcon /></ListItemIcon>
                          <ListItemText primary={<Typography color="textPrimary">Billing</Typography>} />
                      </ListItemButton>
                   </NavLink>
                ]}
                {settingsUrl && [
                  <NavLink to={settingsUrl} style={{textDecoration:'none'}} key="settings">
                     <ListItemButton sx={{ pl: 4 }}>
                         <ListItemIcon><EditIcon /></ListItemIcon>
                         <ListItemText primary={<Typography color="textPrimary">Settings</Typography>} />
                     </ListItemButton>
                  </NavLink>
                ]}
              </List>
            </Collapse>
          </>)}
       </List>
      </>)}
    </>
  );
}
